import React from 'react';
import HeaderOne from '../Components/Header/HeaderOne';
import FooterOne from '../Components/Footer/FooterOne';

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderOne />
      <div className="privacy-policy-container container">
        <h1>Privacy Policy</h1>
        <p style={{marginBottom:"20px"}}>At 3d Plan, we prioritize the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you interact with our website and services. By using our services, you agree to the practices described in this policy.</p>

        <h2>1. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul style={{marginBottom:"20px"}}>
          <li><strong>Personal Information:</strong> When you contact us, sign up for services, or make inquiries, we may collect personal details such as your name, email address, phone number, and any other details you voluntarily provide.</li>
          <li><strong>Usage Data:</strong> We collect data on how you interact with our website, including your IP address, browser type, device information, and browsing patterns. This helps us improve our site and services.</li>
          <li><strong>Cookies:</strong> We use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and provide tailored content. You can manage cookie settings through your browser.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul style={{marginBottom:"20px"}}>
          <li>To provide, maintain, and improve our services.</li>
          <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
          <li>To send you updates, newsletters, or promotional materials related to our services (with your consent).</li>
          <li>To improve website functionality, user experience, and overall performance.</li>
          <li>To comply with legal obligations and resolve any disputes.</li>
        </ul>

        <h2>3. Data Sharing and Disclosure</h2>
        <p>We do not sell, rent, or trade your personal information with third parties. However, we may share your information under the following circumstances:</p>
        <ul style={{marginBottom:"20px"}}>
          <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist in delivering our services (e.g., payment processors, website hosting, etc.).</li>
          <li><strong>Legal Compliance:</strong> We may disclose your information if required by law, or in response to valid legal requests such as a court order, government investigation, or other legal processes.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of our assets, your information may be transferred as part of that transaction.</li>
        </ul>

        <h2>4. Data Security</h2>
        <p style={{marginBottom:"20px"}}>We take reasonable measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. Despite our efforts, no security measure is completely foolproof, and we cannot guarantee the absolute security of your data.</p>

        <h2>5. Your Data Rights</h2>
        <p>Depending on your location, you may have the following rights regarding your personal information:</p>
        <ul style={{marginBottom:"20px"}}>
          <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
          <li><strong>Correction:</strong> You can request corrections to any inaccuracies in your data.</li>
          <li><strong>Deletion:</strong> You may request the deletion of your personal data under certain circumstances.</li>
          <li><strong>Opt-Out:</strong> You can opt out of receiving promotional communications at any time by following the unsubscribe instructions in our emails.</li>
        </ul>

        <h2>6. Third-Party Links</h2>
        <p style={{marginBottom:"20px"}}>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those external sites. We recommend reviewing their privacy policies before providing any personal information.</p>

        <h2>7. Children's Privacy</h2>
        <p style={{marginBottom:"20px"}}>Our services are not intended for individuals under the age of 18, and we do not knowingly collect personal information from children. If we become aware that we have inadvertently collected such information, we will take steps to delete it.</p>

        <h2>8. Changes to This Policy</h2>
        <p style={{marginBottom:"20px"}}>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page, and we encourage you to review this policy periodically.</p>

        <h2>9. Contact Us</h2>
        <p>If you have any questions or concerns about our Privacy Policy or how we handle your personal information, please contact us at:</p>
        <p><strong>3d Plan</strong><br />
        Phone: +91 9876543210<br />
        Email: 3d Plan@example.com<br />
        </p>
      </div>
      <FooterOne />
    </>
  );
}

export default PrivacyPolicy;
