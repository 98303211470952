import React from "react";
import footerLogo from "../../assets/img/footer-logo.png";
import { Link } from "react-router-dom";

const FooterCta = () => {
  function scrollToTop() {
    
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scrolling animation
    });
}
  return (
    <div className="footer-cta-wrapper">
      <div className="container">
        <div className="footer-cta-bg-wrapper">
          <div className="row justify-content-around align-items-center">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="footer-logo">
                <Link to="/">
                  <img src={footerLogo} alt="logo" onClick={scrollToTop}/>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 ps-lg-0 offset-lg-1 col-12">
              <div className="footer-middle-text mt-4 mb-4 mt-md-0 mb-md-0 text-white">
                <p>
                  Step into the future of architecture with our 3d Plan
                  project. Experience innovation, precision, and immersive
                  design like never before.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-12">
              <div className="footer-social-icon ms-md-5 text-lg-md-end">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCta;
